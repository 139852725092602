import { t } from '../helpers/t-converter'

export const paymentTypes = (type: string): string => {
  switch (type) {
    case 'prepayment': return t('Аванс')
    case 'postpayment': return t('Післяплата')
    case 'standard': return t('Одноразова поставка')
    case 'recurring': return t('Повторювана поставка')
    default: return ''
  }
}

export const paymentTypesList = [
  'prepayment',
  'postpayment'
]

export const scheme = (scheme: string): string => {
  switch (scheme) {
    case 'classification': return 'CPV'
    case 'road': return 'road'
    case 'gmdn': return 'gmdn'
    case 'inn': return 'INN'
    case 'ccce_ua': return 'ccce_ua'
    default: return ''
  }
}
export const schemeList = [
  'classification',
  'road',
  'gmdn',
  'inn',
  'ccce_ua'
]
