import { t } from '../helpers/t-converter'
import type * as UtilDocuments from 'util/documents/util-documents'

export const procurementMethodTypes = (status: string): string => {
  switch (status) {
    case 'belowThreshold': return t('procurementMethodType.belowThreshold')
    case 'requestForProposal': return t('procurementMethodType.requestForProposal')
    case 'aboveThreshold': return t('procurementMethodType.aboveThreshold')
    case 'aboveThresholdUA': return t('procurementMethodType.aboveThresholdUA')
    case 'aboveThresholdEU': return t('procurementMethodType.aboveThresholdEU')
    case 'aboveThresholdUA.defense': return t('procurementMethodType.aboveThresholdUA.defense')
    case 'negotiation': return t('procurementMethodType.negotiation')
    case 'negotiation.quick': return t('procurementMethodType.negotiation.quick')
    case 'competitiveDialogueUA': return t('procurementMethodType.competitiveDialogueUA')
    case 'competitiveDialogueEU': return t('procurementMethodType.competitiveDialogueEU')
    case 'competitiveDialogueUA.stage2': return t('procurementMethodType.competitiveDialogueUA.stage2')
    case 'competitiveDialogueEU.stage2': return t('procurementMethodType.competitiveDialogueEU.stage2')
    case 'reporting': return t('procurementMethodType.reporting')
    case 'reporting.COVID-19': return t('procurementMethodType.reporting.COVID-19')
    case 'reporting.war_for_freedom': return t('procurementMethodType.reporting.war_for_freedom')
    case 'reporting.defense': return t('procurementMethodType.reporting.defense')
    case 'reporting.catalogue': return t('procurementMethodType.reporting.catalogue')
    case 'simple.defense': return t('procurementMethodType.simple.defense')
    case 'esco': return t('procurementMethodType.esco')
    case 'closeFrameworkAgreementUA': return t('procurementMethodType.closeFrameworkAgreementUA')
    case 'closeFrameworkAgreementSelectionUA': return t('procurementMethodType.closeFrameworkAgreementSelectionUA')
    case 'centralizedProcurement': return t('procurementMethodType.centralizedProcurement')
    case 'priceQuotation': return t('procurementMethodType.priceQuotation')
    case 'competitiveOrdering': return t('procurementMethodType.competitiveOrdering')
    default: return ''
  }
}

export const procurementMethodTypesSearchList = [
  'belowThreshold',
  'requestForProposal',
  'aboveThreshold',
  'aboveThresholdUA',
  'aboveThresholdEU',
  'aboveThresholdUA.defense',
  'negotiation',
  'negotiation.quick',
  'competitiveDialogueUA',
  'competitiveDialogueEU',
  'competitiveDialogueUA.stage2',
  'competitiveDialogueEU.stage2',
  'reporting',
  'reporting.COVID-19',
  'reporting.defense',
  'reporting.catalogue',
  'simple.defense',
  'esco',
  'closeFrameworkAgreementUA',
  'closeFrameworkAgreementSelectionUA',
  'centralizedProcurement',
  'priceQuotation',
  'competitiveOrdering'
]

export const complainDurationTypes = (key: string): string => {
  switch (key) {
    case 'awardComplainDuration': return t('Тривалість оскарження результатів визначення переможця')
    case 'cancellationComplainDuration': return t('Тривалість оскарження відміни закупівлі')
    case 'clarificationUntilDuration': return t('Кількість днів для надання пояснень на звернення')
    case 'hasAuction': return t('Електронний аукціон')
    case 'hasAwardComplaints': return t('Оскарження результатів визначення переможця')
    case 'hasAwardingOrder': return t('Послідовний порядок розгляду пропозицій')
    case 'hasCancellationComplaints': return t('Оскарження відміни закупівлі')
    case 'hasPreSelectionAgreement': return t('Відбір з попередньо кваліфікованими учасниками')
    case 'hasPrequalification': return t('Попередня кваліфікація')
    case 'hasQualificationComplaints': return t('Оскарження результатів кваліфікації ')
    case 'hasTenderComplaints': return t('Оскарження умов тендерної документації ')
    case 'hasValueEstimation': return t('Очікувана вартість')
    case 'hasValueRestriction': return t('Ціна пропозиції обмежена очікуваною вартістю')
    case 'minBidsNumber': return t('Мінімальна кількість пропозицій')
    case 'qualificationComplainDuration': return t('Тривалість оскарження результатів кваліфікації')
    case 'qualificationDuration': return t('Тривалість кваліфікації')
    case 'restricted': return t('Обмежений доступ')
    case 'tenderComplainRegulation': return t('Кількість днів між кінцевою датою оскарження умов та кінцевим строком подання пропозицій')
    case 'valueCurrencyEquality': return t('Валюта пропозиції визначена очікуваною вартістю')
    default: return ''
  }
}

export const complainDurationValues = (key: string, status: string): UtilDocuments.FieldConfig => {
  switch (key) {
    case 'awardComplainDuration': return awardComplainDuration(status)
    case 'cancellationComplainDuration': return cancellationComplainDuration(status)
    case 'clarificationUntilDuration': return clarificationUntilDuration(status)
    case 'hasQualificationComplaints': return hasQualificationComplaints(status)
    case 'hasValueEstimation': return hasValueEstimation(status)
    case 'qualificationComplainDuration': return qualificationComplainDuration(status)
    case 'qualificationDuration': return qualificationDuration(status)
    case 'tenderComplainRegulation': return tenderComplainRegulation(status)
    default: return { defaultValue: 0, unit: t('поле не зазначене у конфігурації') }
  }
}

export const configsList = [
  'awardComplainDuration',
  'cancellationComplainDuration',
  'clarificationUntilDuration',
  'hasQualificationComplaints',
  'hasValueEstimation',
  'qualificationComplainDuration',
  'qualificationDuration',
  'tenderComplainRegulation'
]

export const awardComplainDuration = (status: string): UtilDocuments.FieldConfig => {
  switch (status) {
    case 'competitiveOrdering': return { defaultValue: 0 }
    case 'belowThreshold': return { defaultValue: 0 }
    case 'requestForProposal': return { defaultValue: 2, unit: t('робочих днів') }
    case 'aboveThreshold': return { defaultValue: 10, unit: t('календарних днів') }
    case 'aboveThresholdUA': return { defaultValue: 10, unit: t('календарних днів') }
    case 'aboveThresholdEU': return { defaultValue: 10, unit: t('календарних днів') }
    case 'negotiation': return { defaultValue: 10, unit: t('календарних днів') }
    case 'negotiation.quick': return { defaultValue: 10, unit: t('календарних днів') }
    case 'simple.defense': return { defaultValue: 10, unit: t('календарних днів') }
    case 'competitiveDialogueUA': return { defaultValue: 10, unit: t('календарних днів') }
    case 'competitiveDialogueEU': return { defaultValue: 10, unit: t('календарних днів') }
    case 'competitiveDialogueUA.stage2': return { defaultValue: 10, unit: t('календарних днів') }
    case 'competitiveDialogueEU.stage2': return { defaultValue: 10, unit: t('календарних днів') }
    case 'reporting': return { defaultValue: 0 }
    case 'esco': return { defaultValue: 10, unit: t('календарних днів') }
    case 'closeFrameworkAgreementUA': return { defaultValue: 10, unit: t('календарних днів') }
    case 'closeFrameworkAgreementSelectionUA': return { defaultValue: 0 }
    case 'priceQuotation': return { defaultValue: 0 }
    case 'aboveThresholdUA.defense': return { defaultValue: 10, unit: t('календарних днів') }
    default: return { defaultValue: 0, unit: t('поле не зазначене у конфігурації') }
  }
}

export const cancellationComplainDuration = (status: string): UtilDocuments.FieldConfig => {
  switch (status) {
    case 'competitiveOrdering': return { defaultValue: 0 }
    case 'belowThreshold': return { defaultValue: 0 }
    case 'requestForProposal': return { defaultValue: 0 }
    case 'aboveThreshold': return { defaultValue: 10, unit: 'календарних днів' }
    case 'aboveThresholdUA': return { defaultValue: 10, unit: 'календарних днів' }
    case 'aboveThresholdEU': return { defaultValue: 10, unit: 'календарних днів' }
    case 'negotiation': return { defaultValue: 10, unit: 'календарних днів' }
    case 'negotiation.quick': return { defaultValue: 10, unit: 'календарних днів' }
    case 'simple.defense': return { defaultValue: 10, unit: 'календарних днів' }
    case 'competitiveDialogueUA': return { defaultValue: 10, unit: 'календарних днів' }
    case 'competitiveDialogueEU': return { defaultValue: 10, unit: 'календарних днів' }
    case 'competitiveDialogueUA.stage2': return { defaultValue: 10, unit: 'календарних днів' }
    case 'competitiveDialogueEU.stage2': return { defaultValue: 10, unit: 'календарних днів' }
    case 'reporting': return { defaultValue: 0 }
    case 'esco': return { defaultValue: 10, unit: 'календарних днів' }
    case 'closeFrameworkAgreementUA': return { defaultValue: 10, unit: 'календарних днів' }
    case 'closeFrameworkAgreementSelectionUA': return { defaultValue: 0 }
    case 'priceQuotation': return { defaultValue: 0 }
    case 'aboveThresholdUA.defense': return { defaultValue: 10, unit: 'календарних днів' }
    default: return { defaultValue: 0 }
  }
}

export const qualificationDuration = (status: string): UtilDocuments.FieldConfig => {
  switch (status) {
    case 'competitiveOrdering': return { defaultValue: 0 }
    case 'belowThreshold': return { defaultValue: 20, unit: t('робочих днів') }
    case 'requestForProposal': return { defaultValue: 20, unit: t('робочих днів') }
    case 'aboveThreshold': return { defaultValue: 0 }
    case 'aboveThresholdUA': return { defaultValue: 0 }
    case 'aboveThresholdEU': return { defaultValue: 20, unit: t('робочих днів') }
    case 'negotiation': return { defaultValue: 0 }
    case 'negotiation.quick': return { defaultValue: 0 }
    case 'simple.defense': return { defaultValue: 0 }
    case 'competitiveDialogueUA': return { defaultValue: 20, unit: t('робочих днів') }
    case 'competitiveDialogueEU': return { defaultValue: 20, unit: t('робочих днів') }
    case 'competitiveDialogueUA.stage2': return { defaultValue: 0 }
    case 'competitiveDialogueEU.stage2': return { defaultValue: 20, unit: t('робочих днів') }
    case 'reporting': return { defaultValue: 0 }
    case 'esco': return { defaultValue: 20, unit: t('робочих днів') }
    case 'closeFrameworkAgreementUA': return { defaultValue: 20, unit: t('робочих днів') }
    case 'closeFrameworkAgreementSelectionUA': return { defaultValue: 0 }
    case 'priceQuotation': return { defaultValue: 0 }
    case 'aboveThresholdUA.defense': return { defaultValue: 0 }
    default: return { defaultValue: 0 }
  }
}

export const qualificationComplainDuration = (status: string): UtilDocuments.FieldConfig => {
  switch (status) {
    case 'competitiveOrdering': return { defaultValue: 0 }
    case 'belowThreshold': return { defaultValue: 0 }
    case 'requestForProposal': return { defaultValue: 10, unit: t('робочих днів') }
    case 'aboveThreshold': return { defaultValue: 0 }
    case 'aboveThresholdUA': return { defaultValue: 0 }
    case 'aboveThresholdEU': return { defaultValue: 5, unit: 'календарних днів' }
    case 'negotiation': return { defaultValue: 0 }
    case 'negotiation.quick': return { defaultValue: 0 }
    case 'simple.defense': return { defaultValue: 0 }
    case 'competitiveDialogueUA': return { defaultValue: 5, unit: 'календарних днів' }
    case 'competitiveDialogueEU': return { defaultValue: 5, unit: 'календарних днів' }
    case 'competitiveDialogueUA.stage2': return { defaultValue: 0 }
    case 'competitiveDialogueEU.stage2': return { defaultValue: 5, unit: 'календарних днів' }
    case 'reporting': return { defaultValue: 0 }
    case 'esco': return { defaultValue: 5, unit: 'календарних днів' }
    case 'closeFrameworkAgreementUA': return { defaultValue: 5, unit: 'календарних днів' }
    case 'closeFrameworkAgreementSelectionUA': return { defaultValue: 0 }
    case 'priceQuotation': return { defaultValue: 0 }
    case 'aboveThresholdUA.defense': return { defaultValue: 0 }
    default: return { defaultValue: 0 }
  }
}

export const hasValueEstimation = (status: string): UtilDocuments.FieldConfig => {
  switch (status) {
    case 'competitiveOrdering': return { defaultValue: true }
    case 'belowThreshold': return { defaultValue: true }
    case 'requestForProposal': return { defaultValue: false }
    case 'aboveThreshold': return { defaultValue: true }
    case 'aboveThresholdUA': return { defaultValue: true }
    case 'aboveThresholdEU': return { defaultValue: true }
    case 'negotiation': return { defaultValue: true }
    case 'negotiation.quick': return { defaultValue: true }
    case 'simple.defense': return { defaultValue: true }
    case 'competitiveDialogueUA': return { defaultValue: true }
    case 'competitiveDialogueEU': return { defaultValue: true }
    case 'competitiveDialogueUA.stage2': return { defaultValue: true }
    case 'competitiveDialogueEU.stage2': return { defaultValue: true }
    case 'reporting': return { defaultValue: true }
    case 'esco': return { defaultValue: false }
    case 'closeFrameworkAgreementUA': return { defaultValue: true }
    case 'closeFrameworkAgreementSelectionUA': return { defaultValue: true }
    case 'priceQuotation': return { defaultValue: true }
    case 'aboveThresholdUA.defense': return { defaultValue: true }
    default: return { defaultValue: false }
  }
}

export const clarificationUntilDuration = (status: string): UtilDocuments.FieldConfig => {
  switch (status) {
    case 'electronicCatalogue': return { defaultValue: 3, unit: 'робочих днів' }
    case 'dynamicPurchasingSystem': return { defaultValue: 3, unit: 'робочих днів' }
    case 'competitiveOrdering': return { defaultValue: 3, unit: 'календарних днів' }
    case 'belowThreshold': return { defaultValue: 1, unit: 'робочих днів' }
    case 'requestForProposal': return { defaultValue: 4, unit: 'календарних днів' }
    case 'aboveThreshold': return { defaultValue: 3, unit: 'робочих днів' }
    case 'aboveThresholdUA': return { defaultValue: 3, unit: 'робочих днів' }
    case 'aboveThresholdEU': return { defaultValue: 3, unit: 'робочих днів' }
    case 'negotiation': return { defaultValue: 0 }
    case 'negotiation.quick': return { defaultValue: 0 }
    case 'simple.defense': return { defaultValue: 3, unit: 'робочих днів' }
    case 'competitiveDialogueUA': return { defaultValue: 3, unit: 'робочих днів' }
    case 'competitiveDialogueEU': return { defaultValue: 3, unit: 'робочих днів' }
    case 'competitiveDialogueUA.stage2': return { defaultValue: 3, unit: 'робочих днів' }
    case 'competitiveDialogueEU.stage2': return { defaultValue: 3, unit: 'робочих днів' }
    case 'reporting': return { defaultValue: 0 }
    case 'esco': return { defaultValue: 3, unit: 'робочих днів' }
    case 'closeFrameworkAgreementUA': return { defaultValue: 3, unit: 'робочих днів' }
    case 'closeFrameworkAgreementSelectionUA': return { defaultValue: 0 }
    case 'priceQuotation': return { defaultValue: 0 }
    case 'aboveThresholdUA.defense': return { defaultValue: 3, unit: 'робочих днів' }
    default: return { defaultValue: 0 }
  }
}

export const tenderComplainRegulation = (status: string): UtilDocuments.FieldConfig => {
  switch (status) {
    case 'competitiveOrdering': return { defaultValue: 0 }
    case 'belowThreshold': return { defaultValue: 0 }
    case 'requestForProposal': return { defaultValue: 0 }
    case 'aboveThreshold': return { defaultValue: 3, unit: 'календарних днів' }
    case 'aboveThresholdUA': return { defaultValue: 4, unit: 'календарних днів' }
    case 'aboveThresholdEU': return { defaultValue: 4, unit: 'календарних днів' }
    case 'negotiation': return { defaultValue: 0 }
    case 'negotiation.quick': return { defaultValue: 0 }
    case 'simple.defense': return { defaultValue: 2, unit: 'робочих днів' }
    case 'competitiveDialogueUA': return { defaultValue: 4, unit: 'календарних днів' }
    case 'competitiveDialogueEU': return { defaultValue: 4, unit: 'календарних днів' }
    case 'competitiveDialogueUA.stage2': return { defaultValue: 4, unit: 'календарних днів' }
    case 'competitiveDialogueEU.stage2': return { defaultValue: 4, unit: 'календарних днів' }
    case 'reporting': return { defaultValue: 0 }
    case 'esco': return { defaultValue: 4, unit: 'календарних днів' }
    case 'closeFrameworkAgreementUA': return { defaultValue: 4, unit: 'календарних днів' }
    case 'closeFrameworkAgreementSelectionUA': return { defaultValue: 0 }
    case 'priceQuotation': return { defaultValue: 0 }
    case 'aboveThresholdUA.defense': return { defaultValue: 2, unit: 'робочих днів' }
    default: return { defaultValue: 0 }
  }
}

export const hasQualificationComplaints = (status: string): UtilDocuments.FieldConfig => {
  switch (status) {
    case 'competitiveOrdering': return { defaultValue: false }
    case 'belowThreshold': return { defaultValue: false }
    case 'requestForProposal': return { defaultValue: false }
    case 'aboveThreshold': return { defaultValue: false }
    case 'aboveThresholdUA': return { defaultValue: false }
    case 'aboveThresholdEU': return { defaultValue: true }
    case 'negotiation': return { defaultValue: false }
    case 'negotiation.quick': return { defaultValue: false }
    case 'simple.defense': return { defaultValue: false }
    case 'competitiveDialogueUA': return { defaultValue: true }
    case 'competitiveDialogueEU': return { defaultValue: true }
    case 'competitiveDialogueUA.stage2': return { defaultValue: false }
    case 'competitiveDialogueEU.stage2': return { defaultValue: true }
    case 'reporting': return { defaultValue: false }
    case 'esco': return { defaultValue: true }
    case 'closeFrameworkAgreementUA': return { defaultValue: true }
    case 'closeFrameworkAgreementSelectionUA': return { defaultValue: false }
    case 'priceQuotation': return { defaultValue: false }
    case 'aboveThresholdUA.defense': return { defaultValue: false }
    default: return { defaultValue: false }
  }
}
