import { useEffect, useState, useCallback } from 'react'

interface Resolution {
  mobile: boolean
  tablet: boolean
  desktop: boolean
}

export const useResolution = (): Resolution => {
  const [resolution, setResolution] = useState({
    mobile: window.innerWidth <= 640,
    tablet: window.innerWidth > 640 && window.innerWidth <= 992,
    desktop: window.innerWidth > 992
  })

  const updateDimensions = useCallback(() => {
    setResolution({
      mobile: window.innerWidth <= 640,
      tablet: window.innerWidth > 640 && window.innerWidth <= 992,
      desktop: window.innerWidth > 992
    })
  }, [])

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [updateDimensions])

  return resolution
}
