import { checkFetchError } from 'util/core/tool/check-fetch-error'
import { appConfig } from 'util/core/config/app-config.instance'
import { type ConfigInterface } from 'util/core/config/config.interface'

class DataAccessList {
  public async getList (list: string, api: keyof ConfigInterface): Promise<any> {
    const response = await fetch(`${appConfig.get(api)}/lists/${list}.json`)
    checkFetchError(response.status)
    return await response.json()
  }
}

export const dataAccessList = new DataAccessList()
