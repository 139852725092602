import { t } from '../helpers/t-converter'

export const typeSubjectProcurementShortTitles = (type: string): string => {
  switch (type) {
    case 'goods': return t('Закупівля товарів')
    case 'works': return t('Закупівля робіт')
    case 'services': return t('Закупівля послуг')
    default: return ''
  }
}

export const typeSubjectProcurementTitles = (type: string): string => {
  switch (type) {
    case 'goods': return t('Товари')
    case 'works': return t('Роботи')
    case 'services': return t('Послуги')
    default: return ''
  }
}
export const typeSubjectProcurementTitlesList = [
  'goods',
  'works',
  'services'
]
