import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { LogoEn, LogoUk } from 'ui/core/logos'
import { appConfig } from 'util/core/config/app-config.instance'
import UiFooter from 'ui/core/footer/footer'

const Footer: React.FC = () => {
  const { t, i18n } = useTranslation()
  const logoPaths: Record<string, string> = {
    uk: LogoUk,
    en: LogoEn
  }

  const filesLink = appConfig.get('FILES')

  const menu = [
    {
      name: t('Тарифи та реквізити'),
      link: '/info/tariffs'
    },
    {
      name: t('Договір'),
      link: `${filesLink}/agreement_dzo_${i18n.language}.pdf`,
      outerLink: true
    },
    {
      name: t('Регламент'),
      link: '/info/regulations'
    },
    {
      name: t('Контакти'),
      link: '/info/contacts'
    }
  ]

  return (
    <UiFooter logoPaths={logoPaths} menu={menu} />
  )
}
export default withTranslation('dzo')(Footer)
