export const toFormatedNumber = (number: string | number): string => {
  if (typeof number === 'number') {
    return number.toLocaleString()
  } else {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }
}
export const toUnformatedNumber = (number: string): string => {
  return number.replace(/[^\d-,.]/g, '').replace(',', '.')
}
