import React, { Suspense } from 'react'
import { useLocation, Route, Routes, Navigate } from 'react-router-dom'
import * as Styled from './App.styled'
import { appConfig } from 'util/core/config/app-config.instance'
import { authService } from 'util/core/service/auth.service'
import { ScrollTop } from 'ui/core/scroll-top/scroll-top'
import Header from 'feature/header/header'
import Footer from 'feature/footer/footer'
import { Loader } from 'ui/core/loader'
import { useUserProfile } from 'util/core/service/user-provider'
import { useTranslation, withTranslation } from 'react-i18next'
import { SearchProvider } from 'util/search/provider/search-provider'

// Lazy load components

const FeatureSearchTender = React.lazy(async () => await import('./feature/search/feature-search-tender'))
const FeatureMonitoring = React.lazy(async () => await import('./feature/monitoring/feature-monitoring'))
const FeatureTender = React.lazy(async () => await import('./feature/tender/feature-tender'))
const FeatureSearchPlan = React.lazy(async () => await import('./feature/search/feature-search-plan'))
const FeaturePlan = React.lazy(async () => await import('./feature/plan/feature-plan'))
const FeatureSearchContract = React.lazy(async () => await import('./feature/search/feature-search-contract'))
const FeatureSearchClassification = React.lazy(async () => await import('./feature/market/search/feature-search-classification'))
const FeatureSearchCategory = React.lazy(async () => await import('./feature/market/search/feature-search-category'))
const FeatureSearchProduct = React.lazy(async () => await import('./feature/market/search/feature-search-product'))
const FeatureProductMarket = React.lazy(async () => await import('./feature/market/product/product'))
const FeatureMarketCart = React.lazy(async () => await import('./feature/market/cart/cart'))
const FeatureTariffs = React.lazy(async () => await import('./feature/tariffs/feature-tariffs'))
const FeatureContacts = React.lazy(async () => await import('./feature/contacts/feature-contacts'))
const FeatureRegulations = React.lazy(async () => await import('./feature/regulations/feature-regulations'))
const FeatureLogout = React.lazy(async () => await import('./feature/authorization/logout/logout'))
const Homepage = React.lazy(async () => await import('./feature/search/homepage'))
const FeatureError = React.lazy(async () => await import('./feature/error/feature-error'))
const FeatureAuthorization = React.lazy(async () => await import('./feature/authorization/feature-authorization'))
const FeatureCabinet = React.lazy(async () => await import('./feature/cabinet/feature-cabinet'))
const FeatureInfoModal = React.lazy(async () => await import('./feature/info-modal/info-modal'))
// const FeatureRegistration = React.lazy(async () => await import('./feature/registration/feature-registration'))

const App: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { user } = useUserProfile()

  const alternateLinks = Object.keys(appConfig.get('languages')).map(lang => {
    const basename = lang !== appConfig.get('defaultLanguage') ? `/${lang}` : ''
    return {
      rel: 'alternate',
      hrefLang: lang,
      href: `https://www.dzo.com.ua${basename}${location.pathname}`
    }
  })

  return (
    <>
        <title>{t('Офіційний майданчик Prozorro (Прозоро) - Державні публічні закупівлі в Україні: тендери, аукціони, закупки, торги.')}</title>
        <meta name='description' content={t('Держзакупівлі.Онлайн - офіційний майданчик в системі Prozorro (Прозоро). 🔍 Швидкий пошук тендерів. ⭐ Всі державні публічні закупівлі України. ⬆️ Реєструйтесь та приймайте участь!')} />
        {location.pathname.includes('/js/sign') &&
          <meta name='robots' content='noindex' />
        }
        {alternateLinks.map(link => <link key={link.hrefLang} {...link} />)}
        <link rel='canonical' href={`https://www.dzo.com.ua${location.pathname}`} />

        <Header />
        <Styled.Content>
          <Routes>
            <Route path='/tenders' element={
              <Suspense fallback={<Loader />}>
                <FeatureSearchTender />
              </Suspense>
            } />
            <Route path='/tenders/external/:tenderId' element={
              <Suspense fallback={<Loader />}>
                <FeatureTender />
              </Suspense>
            } />
            <Route path='/tenders/:tenderId/monitorings/:id/*' element={
              <Suspense fallback={<Loader />}>
                <FeatureMonitoring />
              </Suspense>
            } />
            <Route path='/tenders/:id/monitorings' element={
              <Suspense fallback={<Loader />}>
                <Navigate to='/tenders/:id' replace />
              </Suspense>
            } />

            <Route path='/tenders/:id/*' element={
              <Suspense fallback={<Loader />}>
                <FeatureTender />
              </Suspense>
            } />
            <Route path='/plans' element={
              <Suspense fallback={<Loader />}>
                <FeatureSearchPlan />
              </Suspense>
            } />
            <Route path='/plans/:id' element={
              <Suspense fallback={<Loader />}>
                <FeaturePlan />
              </Suspense>
            } />

            <Route path='/contracts' element={
              <Suspense fallback={<Loader />}>
                <FeatureSearchContract />
              </Suspense>
            } />

            <Route path='/market' element={
              <Suspense fallback={<Loader />}>
                <FeatureSearchClassification />
              </Suspense>
            } />
            <Route path='/market/:classificationId' element={
              <Suspense fallback={<Loader />}>
                <FeatureSearchCategory />
              </Suspense>
            } />
            <Route path='/market/categories' element={
              <Navigate to='/market' replace />
            } />
            <Route path='/market/category' element={
              <Navigate to='/market' replace />
            } />
            <Route path='/market/category/:categoryId' element={
              <Suspense fallback={<Loader />}>
                <FeatureSearchProduct hasFilters filterBy='category' />
              </Suspense>
            } />
            <Route path='/market/profile' element={
              <Navigate to='/market' replace />
            } />
            <Route path='/market/profiles' element={
              <Navigate to='/market' replace />
            } />
            <Route path='/market/profile/:profileId' element={
              <Suspense fallback={<Loader />}>
                <FeatureSearchProduct hasFilters filterBy='profile' />
              </Suspense>
            } />
            <Route path='/market/products' element={
              <Suspense fallback={<Loader />}>
                <FeatureSearchProduct />
              </Suspense>
            } />
            <Route path='/market/products/:id' element={
              <Suspense fallback={<Loader />}>
                <FeatureProductMarket />
              </Suspense>
            } />
            <Route path='/market/prozorro-products/:id' element={
              <Suspense fallback={<Loader />}>
                <FeatureProductMarket isProzorroProduct={true} />
              </Suspense>
            } />
            <Route path='/market/cart/*' element={
              <Suspense fallback={<Loader />}>
              { (!authService.isLoggedIn || user?.type === 'seller')
                ? <FeatureError error="404" />
                : <FeatureMarketCart />
              }
              </Suspense>
            } />

            <Route path='/info/tariffs' element={
              <Suspense fallback={<Loader />}>
                <FeatureTariffs />
              </Suspense>
            } />
            <Route path='/info/contacts' element={
              <Suspense fallback={<Loader />}>
                <FeatureContacts />
              </Suspense>
            } />
            <Route path='/info/regulations' element={
              <Suspense fallback={<Loader />}>
                <FeatureRegulations />
              </Suspense>}
            />

            {appConfig.get('ENABLE_CABINET') &&
              <Route path='/cabinet/*' element={
                !authService.isLoggedIn
                  ? <Navigate to='/' />
                  : <>
                    <Suspense fallback={<Loader />}>
                      <FeatureCabinet />
                    </Suspense>
                  </>
              }/>
            }

            <Route path='/login/*' element={
              authService.isLoggedIn
                ? <Navigate to='/' replace />
                : <Suspense fallback={<Loader />}>
                    <FeatureAuthorization />
                  </Suspense>
            }/>

            {/* <Route path='/registration/*' element={
              !authService.isLoggedIn
                ? <Navigate to='/' replace />
                : <Suspense fallback={<Loader />}>
                    <FeatureRegistration />
                  </Suspense>
            }/> */}

            <Route path='logout' element={
              <Suspense fallback={<Loader />}>
                <FeatureLogout />
              </Suspense>
            } />

            <Route path='/' element={
              <Suspense fallback={<Loader />}>
                <SearchProvider type={'tenders'}>
                  <Homepage />
                </SearchProvider>
              </Suspense>
            }/>

            <Route path="*" element={
              <Suspense fallback={<Loader />}>
                <FeatureError error="404" />
              </Suspense>
            } />
          </Routes>
          <ScrollTop />
          <FeatureInfoModal />
        </Styled.Content>
        <Footer />
    </>
  )
}

export default withTranslation('dzo')(App)
