import { fetchTransport } from '../../util/core/transport/fetch.transport'
import { type Schemes } from './type/schemes'
import { Classification } from './type/classification'

class DataAccessClassification {
  public async load (
    scheme: Schemes,
    parentId: string,
    keyword?: string,
    searchIds?: string[]
  ): Promise<Classification[]> {
    const params = new URLSearchParams({
      scheme,
      page: '1',
      perPage: '10000'
    })

    // for keyword search we ignore parentId
    if (keyword && keyword !== '') {
      params.append('keyword', keyword)
    } else if ((searchIds != null) && searchIds.length > 0) {
      searchIds.forEach(
        searchId => { params.append('searchId[]', searchId) }
      )
    } else {
      params.append('parentId', parentId)
    }

    const response = await fetchTransport.get({
      url: '/api/classifications',
      searchParams: params
    })

    if (response.status >= 400 && response.status < 600) {
      throw new Error(
        response.status.toString()
      )
    }

    const json = await response.json()
    const result: Classification[] = []

    if (json['@type'] === 'hydra:Collection') {
      if (json['hydra:member'].length > 0) {
        const items: any[] = json['hydra:member']

        items.forEach(
          item => { this.storeItem(item, result) }
        )
      }
    }

    result.sort((a, b) => this.compareIds(scheme, a.id, b.id))

    return [...new Set(result)]
  }

  private storeItem (item: any, classifications: Classification[]): void {
    let name = (item.name) ? item.name : item.description
    if (item.id === name) name = ''
    const classification = new Classification(
      item.id,
      name,
      item.selectable,
      item.children,
      item.treeLevel
    )

    classifications.push(classification)
  }

  private compareIds (scheme: Schemes, id1: string, id2: string): number {
    if (scheme === 'CPV') {
      const id1Arr = id1.split('-')
      const id2Arr = id2.split('-')
      return (parseInt(id1Arr[0]) > parseInt(id2Arr[0])) ? 1 : -1
    }

    return (id1 > id2) ? 1 : -1
  }
}

export const dataAccessClassification = new DataAccessClassification()
