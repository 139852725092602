import React, { useState } from 'react'
import * as Styled from './header.styled'
import { useTranslation, withTranslation } from 'react-i18next'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { useLocation } from 'react-router-dom'

import { authService } from 'util/core/service/auth.service'

import Logo from './logo/logo'
import { BurgerIcon, CloseIcon, UserIcon } from '../icons'
import NavItem from './nav-item/nav-item'
import { useResolution } from 'util/hooks/useResolution'

interface UiHeaderProps {
  menu: any
  children: React.ReactNode
}

const UiHeader: React.FC<UiHeaderProps> = ({ menu, children }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [open, setOpen] = useState<boolean>(false)
  const { desktop } = useResolution()

  return (
    <Styled.Wrap>
      <Styled.Content open={open}>
        <Logo />
        <ClickAwayListener onClickAway={() => { setOpen(false) }}>
          <Styled.Nav>
            {desktop && children}
            {!authService.isLoggedIn && desktop
              ? <Styled.SignIn>
                  <Styled.Login
                    to='/login'
                    onClick={() => { setOpen(prevOpen => !prevOpen) }}
                    state={{ from: pathname }}
                  >
                    <UserIcon /> {t('Увійти')}
                  </Styled.Login>
                  <Styled.Register href='/registration/sellers' oldDzo title={t('Реєстрація')}>{t('Реєстрація')}</Styled.Register>
                </Styled.SignIn>
              : <NavItem
                title={!desktop ? (open ? <CloseIcon/> : <BurgerIcon/>) : <Styled.User><UserIcon/></Styled.User>}
                menu={menu}
                mobile={!desktop}
                open={open}
                onClick={() => { setOpen(prevOpen => !prevOpen) }}
              />
            }
          </Styled.Nav>
        </ClickAwayListener>
      </Styled.Content>
    </Styled.Wrap>
  )
}

export default withTranslation('dzo')(UiHeader)
