import moment from 'moment'
import {
  type SearchMarketFilters,
  type SearchFilters,
  type SearchFiltersArray, type SearchFiltersBoolean,
  type SearchFiltersClassificators, type SearchFiltersNumber, type SearchFiltersRange,
  type SearchFiltersString, type SearchSortOrder, type SortKeys
} from '../types/search-filters'
import { type Chips, CHIPS_RANGE_FROM_KEY, CHIPS_RANGE_TO_KEY } from '../types/chips'
import { type SearchRecordsType } from '../types/search-records.type'
import { paymentTypes } from '../constants/payment-types'
import { typeSubjectProcurementShortTitles } from '../constants/type-subject-procurement'
import { toFormatedNumber } from '../../core/tool/formated-number'
import { type ChipInterface } from '../types/chip.interface'
import { contractStatusName, planStatusName, tenderStatusName } from '../constants/statuses'
import { procurementMethodTypes } from '../constants/procurement-method-types'
import { type RangeFilterInterface, RangeFilterTypeId } from '../types/range-filter-interface'
import { monitoringFilters } from '../constants/monitoring-filters'
import { tenderFunders } from 'util/core/funders'
import { type BooleanRequirement, type NumberRequirement, type TextRequirement } from '../types/market-requirements'
import { transformValue } from 'util/core/tool/transform-value'
import { convertToBoolean } from 'util/core/tool/convertToBoolean'
import { defaultFiltersMap } from '../provider/search-provider'

type filterFn = (filters: SearchFilters, value: any, dataType?: string) => void

type chipFn = (filters: Chips, value: any, isFrom?: boolean) => void

export class UrlParser {
  private readonly SEARCH_KEYS: Record<string, filterFn> = {
    page:
      (filters: SearchFilters, value: string) => { this.parseIntNumber(filters, value, 'page') },
    perPage:
      (filters: SearchFilters, value: string) => { this.parseIntNumber(filters, value, 'perPage') },
    typeSubjectProcurement:
      (filters: SearchFilters, value: string) => { this.parseString(filters, value, 'typeSubjectProcurement') },
    paymentType:
      (filters: SearchFilters, value: string) => { this.parseString(filters, value, 'paymentType') },
    keyword:
      (filters: SearchFilters, value: string) => { this.parseString(filters, value, 'keyword') },
    planYear:
      (filters: SearchFilters, value: string) => { this.parseString(filters, value, 'planYear') },
    sortOrder:
      (filters: SearchFilters, value: string) => { this.parseSort(filters, value, 'sortOrder') },
    'valueAmount.from':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'valueAmount', true) },
    'valueAmount.to':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'valueAmount', false) },
    'enquiryPeriod.from':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'enquiryPeriod', true) },
    'enquiryPeriod.to':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'enquiryPeriod', false) },
    'auctionPeriod.from':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'auctionPeriod', true) },
    'auctionPeriod.to':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'auctionPeriod', false) },
    'tenderPeriod.from':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'tenderPeriod', true) },
    'tenderPeriod.to':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'tenderPeriod', false) },
    'qualificationPeriod.from':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'qualificationPeriod', true) },
    'qualificationPeriod.to':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'qualificationPeriod', false) },
    'publicationDate.from':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'publicationDate', true) },
    'publicationDate.to':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'publicationDate', false) },
    'valueSum.from':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'valueSum', true) },
    'valueSum.to':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'valueSum', false) },
    'valueBudget.from':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'valueBudget', true) },
    'valueBudget.to':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'valueBudget', false) },
    'plansPeriod.from':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'plansPeriod', true) },
    'plansPeriod.to':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'plansPeriod', false) },
    'plansPublished.from':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'plansPublished', true) },
    'plansPublished.to':
      (filters: SearchFilters, value: string) => { this.parseRange(filters, value, 'plansPublished', false) },
    'status[]':
      (filters: SearchFilters, value: string) => { this.parseArray(filters, value, 'status') },
    'classification[]':
      (filters: SearchFilters, value: string) => { this.parseArray(filters, value, 'classification') },
    'road[]':
      (filters: SearchFilters, value: string) => { this.parseArray(filters, value, 'road') },
    'gmdn[]':
      (filters: SearchFilters, value: string) => { this.parseArray(filters, value, 'gmdn') },
    'inn[]':
      (filters: SearchFilters, value: string) => { this.parseArray(filters, value, 'inn') },
    'organizer[]':
      (filters: SearchFilters, value: string) => { this.parseArray(filters, value, 'organizer') },
    'supplier[]':
      (filters: SearchFilters, value: string) => { this.parseArray(filters, value, 'supplier') },
    'region[]':
      (filters: SearchFilters, value: string) => { this.parseArray(filters, value, 'region') },
    'fundManager[]':
      (filters: SearchFilters, value: string) => { this.parseArray(filters, value, 'fundManager') },
    'procurementMethodType[]':
      (filters: SearchFilters, value: string) => { this.parseArray(filters, value, 'procurementMethodType') },
    'funder[]':
      (filters: SearchFilters, value: string) => { this.parseArray(filters, value, 'funder') },
    hasActiveMonitoring:
      (filters: SearchFilters, value: string) => { this.parseBoolean(filters, value, 'hasActiveMonitoring') },
    'market_classification[]':
      (filters: SearchFilters, value: string) => { this.parseArray(filters, value, 'market_classification') },
    'relatedCategoryId[]':
      (filters: SearchFilters, value: string) => { this.parseArray(filters, value, 'relatedCategoryId') },
    relatedProfileIds:
      (filters: SearchFilters, value: string) => { this.parseString(filters, value, 'relatedProfileIds') },
    'textRequirements[]':
      (filters: SearchFilters, value: TextRequirement, dataType?: string) => { this.parseMarketFilters(filters, value, 'textRequirements', dataType) },
    'numberRequirements[]':
      (filters: SearchFilters, value: NumberRequirement, dataType?: string) => { this.parseMarketFilters(filters, value, 'numberRequirements', dataType) },
    'booleanRequirements[]':
      (filters: SearchFilters, value: BooleanRequirement, dataType?: string) => { this.parseMarketFilters(filters, value, 'booleanRequirements', dataType) },
    'ccce_ua[]':
      (filters: SearchFilters, value: string) => { this.parseArray(filters, value, 'ccce_ua') }
  }

  private readonly CHIPS_KEYS: Record<string, chipFn> = {
    typeSubjectProcurement:
      (chips: Chips, value: string) => { this.parseChipsString(chips, value, 'typeSubjectProcurement') },
    paymentType:
      (chips: Chips, value: string) => { this.parseChipsString(chips, value, 'paymentType') },
    planYear:
      (chips: Chips, value: string) => { this.parseChipsString(chips, value, 'planYear') },
    keyword:
      (chips: Chips, value: string) => { this.parseChipsString(chips, value, 'keyword') },
    'valueAmount.from':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'valueAmount', true) },
    'valueAmount.to':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'valueAmount', false) },
    'enquiryPeriod.from':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'enquiryPeriod', true) },
    'enquiryPeriod.to':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'enquiryPeriod', false) },
    'auctionPeriod.from':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'auctionPeriod', true) },
    'auctionPeriod.to':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'auctionPeriod', false) },
    'tenderPeriod.from':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'tenderPeriod', true) },
    'tenderPeriod.to':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'tenderPeriod', false) },
    'qualificationPeriod.from':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'qualificationPeriod', true) },
    'qualificationPeriod.to':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'qualificationPeriod', false) },
    'publicationDate.from':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'publicationDate', true) },
    'publicationDate.to':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'publicationDate', false) },
    'valueSum.from':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'valueSum', true) },
    'valueSum.to':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'valueSum', false) },
    'valueBudget.from':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'valueBudget', true) },
    'valueBudget.to':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'valueBudget', false) },
    'plansPeriod.from':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'plansPeriod', true) },
    'plansPeriod.to':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'plansPeriod', false) },
    'plansPublished.from':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'plansPublished', true) },
    'plansPublished.to':
      (chips: Chips, value: string) => { this.parseChipsRange(chips, value, 'plansPublished', false) },
    'status[]':
      (chips: Chips, value: string) => { this.parseChipsArray(chips, value, 'status') },
    'classification[]':
      (chips: Chips, value: string) => { this.parseChipsArray(chips, value, 'classification') },
    'road[]':
      (chips: Chips, value: string) => { this.parseChipsArray(chips, value, 'road') },
    'gmdn[]':
      (chips: Chips, value: string) => { this.parseChipsArray(chips, value, 'gmdn') },
    'inn[]':
      (chips: Chips, value: string) => { this.parseChipsArray(chips, value, 'inn') },
    'organizer[]':
      (chips: Chips, value: string) => { this.parseChipsArray(chips, value, 'organizer') },
    'supplier[]':
      (chips: Chips, value: string) => { this.parseChipsArray(chips, value, 'supplier') },
    'region[]':
      (chips: Chips, value: string) => { this.parseChipsArray(chips, value, 'region') },
    'fundManager[]':
      (chips: Chips, value: string) => { this.parseChipsArray(chips, value, 'fundManager') },
    'procurementMethodType[]':
      (chips: Chips, value: string) => { this.parseChipsArray(chips, value, 'procurementMethodType') },
    'funder[]':
      (chips: Chips, value: string) => { this.parseChipsArray(chips, value, 'funder') },
    hasActiveMonitoring:
      (chips: Chips, value: string) => { this.parseChipsBoolean(chips, value, 'hasActiveMonitoring') },
    'textRequirements[]':
      (chips: Chips, value: { title: string, value: string }) => { this.parseChipsMarketFilters(chips, value, 'textRequirements') },
    'numberRequirements[]':
      (chips: Chips, value: { title: string, value: string }, isFrom?: boolean) => { this.parseChipsMarketFilters(chips, value, 'numberRequirements', isFrom) },
    'booleanRequirements[]':
      (chips: Chips, value: { title: string, value: string }) => { this.parseChipsMarketFilters(chips, value, 'booleanRequirements') },
    'ccce_ua[]':
      (chips: Chips, value: string) => { this.parseChipsArray(chips, value, 'ccce_ua') }
  }

  private readonly searchParams: URLSearchParams

  constructor (
    url: string,
    private readonly t: (token: string) => string,
    private readonly type: SearchRecordsType
  ) {
    this.searchParams = new URLSearchParams(url)
  }

  public get chips (): Chips {
    const chips: Chips = {}

    const pattern = /(textRequirements|numberRequirements|booleanRequirements)\[(\d+)\]\[(requirement|values|minValue|maxValue|value)\](\[\])?/

    const parsedRequirements: SearchMarketFilters = {
      textRequirements: [],
      numberRequirements: [],
      booleanRequirements: []
    }

    this.searchParams.forEach((value, key) => {
      const match = key.match(pattern)
      if (match) {
        const [, requirementType, index, keyType] = match
        const idx = parseInt(index, 10)

        const requirementsArray: any = requirementType === 'textRequirements'
          ? parsedRequirements.textRequirements
          : requirementType === 'numberRequirements'
            ? parsedRequirements.numberRequirements
            : parsedRequirements.booleanRequirements

        if (!requirementsArray[idx]) {
          requirementsArray[idx] = { requirement: '', values: [] }
        }

        const currentRequirement = requirementsArray[idx]

        if (keyType === 'requirement') {
          currentRequirement.requirement = value
        } else if (keyType === 'values') {
          currentRequirement.values?.push(convertToBoolean(value))
        } else if (keyType === 'minValue' || keyType === 'maxValue' || keyType === 'value') {
          // Since minValue and maxValue are optional, ensure they exist before setting them
          currentRequirement[keyType] = value
        }
      }

      if (this.CHIPS_KEYS[key]) {
        this.CHIPS_KEYS[key](chips, value)
      }
    })

    if (parsedRequirements.textRequirements?.length) {
      parsedRequirements.textRequirements.forEach(filter => {
        const { requirement, values } = filter
        if (values.length) {
          values.forEach(value => {
            this.CHIPS_KEYS['textRequirements[]'](chips, { title: requirement, value })
          })
        }
      })
    }
    if (parsedRequirements.numberRequirements?.length) {
      parsedRequirements.numberRequirements.forEach(filter => {
        const { requirement, values, minValue, maxValue } = filter
        if (values.length) {
          values.forEach(value => {
            this.CHIPS_KEYS['numberRequirements[]'](chips, { title: requirement, value })
          })
        }
        if (minValue) {
          this.CHIPS_KEYS['numberRequirements[]'](chips, { title: requirement, value: minValue }, true)
        }
        if (maxValue) {
          this.CHIPS_KEYS['numberRequirements[]'](chips, { title: requirement, value: maxValue }, false)
        }
      })
    }
    if (parsedRequirements.booleanRequirements?.length) {
      parsedRequirements.booleanRequirements.forEach(filter => {
        const { requirement, values, value } = filter
        if (values.length) {
          values.forEach(val => {
            this.CHIPS_KEYS['booleanRequirements[]'](chips, { title: requirement, value: val })
          })
        }
        if (value) {
          this.CHIPS_KEYS['booleanRequirements[]'](chips, { title: requirement, value })
        }
      })
    }

    return chips
  }

  public get filters (): SearchFilters {
    const filters: SearchFilters = { ...defaultFiltersMap[this.type] }

    const pattern = /(textRequirements|numberRequirements|booleanRequirements)\[(\d+)\]\[(requirement|values|value|minValue|maxValue)\](\[\])?/

    const parsedRequirements: SearchMarketFilters = {
      textRequirements: [],
      numberRequirements: [],
      booleanRequirements: []
    }

    this.searchParams.forEach(
      (value, key) => {
        const match = key.match(pattern)
        if (match) {
          const [, requirementType, index, keyType] = match
          const idx = parseInt(index, 10)
          const newValue = keyType !== 'requirement'
            ? requirementType === 'booleanRequirements'
              ? JSON.parse(value)
              : requirementType === 'numberRequirements'
                ? parseFloat(value)
                : value
            : value

          const requirementsArray: any = requirementType === 'textRequirements'
            ? parsedRequirements.textRequirements
            : requirementType === 'numberRequirements'
              ? parsedRequirements.numberRequirements
              : parsedRequirements.booleanRequirements

          if (!requirementsArray[idx]) {
            requirementsArray[idx] = { requirement: '', values: [] }
          }

          const currentRequirement = requirementsArray[idx]

          if (keyType === 'requirement') {
            currentRequirement.requirement = value
          } else if (keyType === 'values') {
            currentRequirement.values?.push(newValue)
          } else if (keyType === 'value' || keyType === 'minValue' || keyType === 'maxValue') {
            currentRequirement[keyType] = newValue
          }
        }

        if (this.SEARCH_KEYS[key]) {
          this.SEARCH_KEYS[key](filters, value)
        }
      }
    )
    if (parsedRequirements.textRequirements?.length) {
      parsedRequirements.textRequirements.forEach(requirement => {
        this.SEARCH_KEYS['textRequirements[]'](filters, requirement, 'string')
      })
    }
    if (parsedRequirements.numberRequirements?.length) {
      parsedRequirements.numberRequirements.forEach(requirement => {
        this.SEARCH_KEYS['numberRequirements[]'](filters, requirement, 'number')
      })
    }
    if (parsedRequirements.booleanRequirements?.length) {
      parsedRequirements.booleanRequirements.forEach(requirement => {
        this.SEARCH_KEYS['booleanRequirements[]'](filters, requirement, 'boolean')
      })
    }

    return filters
  }

  private parseMarketFilters (
    filters: SearchFilters,
    value: TextRequirement | NumberRequirement | BooleanRequirement,
    key: keyof SearchMarketFilters,
    dataType?: string
  ): void {
    if (typeof filters[key] === 'undefined') {
      filters[key] = []
    }
    if (dataType === 'string') {
      (filters[key] as TextRequirement[]).push(value as TextRequirement)
    }
    if (dataType === 'number') {
      (filters[key] as NumberRequirement[]).push(value as NumberRequirement)
    }
    if (dataType === 'boolean') {
      (filters[key] as BooleanRequirement[]).push(value as BooleanRequirement)
    }
  }

  private parseArray (filters: SearchFilters, value: string, key: keyof SearchFiltersArray | keyof SearchFiltersClassificators): void {
    if (typeof filters[key] === 'undefined') {
      filters[key] = []
    }
    (filters[key] as string[]).push(value)
  }

  private parseString (filters: SearchFilters, value: string, key: keyof SearchFiltersString): void {
    filters[key] = value
  }

  private parseSort (filters: SearchFilters, value: string, key: keyof SearchSortOrder): void {
    filters[key] = value as SortKeys
  }

  private parseChipsString (chips: Chips, value: string, key: keyof Chips): void {
    let title = value

    switch (key) {
      case 'keyword':
        if (typeof value !== 'undefined') {
          title = value
        }
        break
      case 'paymentType':
        if (typeof paymentTypes(value) !== 'undefined') {
          title = paymentTypes(value)
        }
        break
      case 'typeSubjectProcurement':
        if ((typeof typeSubjectProcurementShortTitles(value) !== 'undefined')) {
          title = typeSubjectProcurementShortTitles(value)
        }
        break
      case 'planYear':
        if (typeof value !== 'undefined') {
          title = moment(new Date(value)).format('YYYY')
        }
        break
      default:
        return
    }

    chips[key] = [
      {
        key: value,
        title
      }
    ]
  }

  private parseChipsRange (chips: Chips, value: string, key: keyof Chips, isFrom: boolean): void {
    if (typeof chips[key] === 'undefined') {
      chips[key] = []
    }

    let title = value

    if (['tenderPeriod', 'enquiryPeriod', 'auctionPeriod', 'qualificationPeriod', 'publicationDate', 'plansPeriod', 'plansPublished'].includes(key)) {
      title = moment(new Date(value)).format('DD.MM.YYYY')
    }
    if (['valueAmount', 'valueSum', 'valueBudget'].includes(key)) {
      title = toFormatedNumber(value)
    }
    if (isFrom) {
      title = this.t('Від') + ' ' + title
    } else {
      title = this.t('До') + ' ' + title
    }

    (chips[key] as ChipInterface[]).push({
      key: isFrom ? CHIPS_RANGE_FROM_KEY : CHIPS_RANGE_TO_KEY,
      title
    })
  }

  private parseChipsArray (chips: Chips, value: string, key: keyof Chips): void {
    if (typeof chips[key] === 'undefined') {
      chips[key] = []
    }
    let title = value

    if (key === 'status') {
      switch (this.type) {
        case 'tenders':
          title = tenderStatusName(value)
          break
        case 'plans':
          title = planStatusName(value)
          break
        case 'contracts':
          title = contractStatusName(value)
          break
        default:
          title = value
      }
    }

    if (key === 'procurementMethodType') {
      if (procurementMethodTypes(value) !== '') {
        title = procurementMethodTypes(value)
      } else {
        return
      }
    }

    if (key === 'funder') {
      const funderTitle = tenderFunders.getTenderFunders(value)
      if (funderTitle !== '') {
        title = funderTitle
      } else {
        return
      }
    }
    (chips[key] as ChipInterface[]).push({
      key: value,
      title
    })
  }

  private parseChipsMarketFilters (
    chips: Chips,
    data: { title: string, value: string },
    key: keyof Chips,
    isFrom?: boolean
  ): void {
    let title
    let chipKey
    const booleanValues = [true, false, 'true', 'false', 'так', 'ні']
    if (typeof chips[key] === 'undefined') {
      chips[key] = []
    }
    if (isFrom !== undefined) {
      title = `${isFrom ? this.t('Від') : this.t('До')} ${data.value}`
      chipKey = `${data.title}=${isFrom ? 'from' : 'to'}.${data.value}`
    } else if (booleanValues.includes(data.value)) {
      title = `${data.title}: ${transformValue(data.value)}`
    } else {
      title = data.value
    }
    (chips[key] as ChipInterface[]).push({
      key: chipKey ?? `${data.title}=${data.value}`,
      title
    })
  }

  private parseIntNumber (filters: SearchFilters, value: string, key: keyof SearchFiltersNumber): void {
    filters[key] = parseInt(value)
  }

  private parseRange (filters: SearchFilters, value: string, key: keyof SearchFiltersRange, isFrom: boolean): void {
    if (typeof filters[key] === 'undefined') {
      filters[key] = {
        typeId: RangeFilterTypeId
      }
    }
    const filter = filters[key] as RangeFilterInterface
    if (isFrom) {
      filter.from = value
    } else {
      filter.to = value
    }
  }

  private parseBoolean (filters: SearchFilters, value: string, key: keyof SearchFiltersBoolean): void {
    filters[key] = value.toLowerCase() === 'true'
  }

  private parseChipsBoolean (chips: Chips, value: string, key: keyof Chips): void {
    if (typeof chips[key] === 'undefined') {
      chips[key] = []
    }

    const title = monitoringFilters(value)

    chips[key] = [
      {
        key,
        title
      }
    ]
  }
}
