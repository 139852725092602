import type * as UtilDocuments from 'util/documents/util-documents'
import i18n from 'i18next'
import { dataAccessList } from 'data-access/lists/data-access-list'

export class Funders {
  private data: UtilDocuments.OrganizationInterface[]

  constructor () {
    this.data = []
    void this.initialize()
    this.getTenderFunders = this.getTenderFunders.bind(this)
  }

  private async initialize (): Promise<void> {
    if (!this.data.length) {
      this.data = await dataAccessList.getList('tender_funder', 'SEARCH_API')
    }
  }

  get fetchedData (): UtilDocuments.OrganizationInterface[] {
    return this.data
  }

  public getTenderFunders (id: string): string {
    const foundFunder: any = this.fetchedData.find((item: any) => item.identifier.id === id)
    return foundFunder ? foundFunder[`name_${i18n.language}`] : ''
  }
}

export const tenderFunders = new Funders()
