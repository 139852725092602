export class Classification {
  public parent?: Classification

  public readonly children: Classification[] = []

  public isOpen = false

  constructor (
    public readonly id: string,
    public readonly name: string,
    public readonly selectable: boolean,
    public readonly hasChildren: boolean,
    public readonly level: number
  ) {
  }

  public open (): void {
    this.isOpen = true
    this.parent?.open()
  }
}
