import React, { type CSSProperties, useState } from 'react'
import i18n from 'i18next'
import { useTranslation, withTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { type ParticipantType } from 'util/search/constants/participant'
import { authService } from 'util/core/service/auth.service'
import { appConfig } from 'util/core/config/app-config.instance'

import LanguageToggler from 'ui/core/header/language-toggler/language-toggler'
import { UserIcon } from 'ui/core/icons'
import UiHeader from 'ui/core/header/header'
import NavItem from 'ui/core/header/nav-item/nav-item'
import { useUserProfile } from 'util/core/service/user-provider'
import { useResolution } from 'util/hooks/useResolution'
import { theme } from 'theme'

interface MenuItem {
  title?: string
  link?: string
  types?: ParticipantType[]
  sub?: MenuItem[]
  divider?: boolean
  target?: string
  langToggler?: any
  external?: boolean
  style?: CSSProperties
}

const Header: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUserProfile()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { pathname } = useLocation()
  const { desktop } = useResolution()

  const changeLanguage = (newLng: string): void => {
    if (newLng === i18n.language) return

    const oldBasename = (i18n.language === appConfig.get('defaultLanguage')) ? '/' : `/${i18n.language}/`
    const newBasename = (newLng === appConfig.get('defaultLanguage')) ? '/' : `/${newLng}/`

    let newPathname = location.pathname

    // Якщо шлях закінчується на стару мову без слеша, додаємо слеш
    if (!newPathname.endsWith('/')) {
      newPathname += '/'
    }

    // Замінюємо стару мову на нову
    newPathname = newPathname.replace(oldBasename, newBasename)

    window.location.replace(newPathname)
  }

  const mainMenu: MenuItem[] = [
    {
      title: t('Про компанію'),
      link: `${appConfig.get('NEWS')}/pro-kompaniyu/`,
      external: true
    },
    {
      title: t('Корисне'),
      sub: [
        {
          title: t('Інструкції'),
          link: `${appConfig.get('NEWS')}/category/instrukcziyi/`,
          target: '_blank',
          external: true
        },
        {
          title: t('Законодавство'),
          link: `${appConfig.get('NEWS')}/category/zakonodavstvo/`,
          target: '_blank',
          external: true
        },
        {
          title: t('Новини'),
          link: `${appConfig.get('NEWS')}/category/novini/`,
          target: '_blank',
          external: true
        }
      ]
    },
    {
      title: t('Контакти'),
      link: '/info/contacts',
      target: '_self'
    },
    {
      langToggler: <LanguageToggler onChangeLang={changeLanguage} />
    },
    { divider: true }
  ]

  const userMenu: MenuItem[] = (authService.isLoggedIn && user?.type)
    ? {
        buyer: [
          {
            title: t('Моя Компанія'),
            link: `${appConfig.get('OLD_DZO')}/cabinet/company`,
            external: true
          },
          {
            title: t('Кваліфікація до відкритої рамкової угоди'),
            link: `${appConfig.get('OLD_DZO')}/cabinet/frameworks`,
            external: true
          },
          {
            title: t('Мої закупівлі'),
            link: `${appConfig.get('OLD_DZO')}/cabinet/tenders`,
            external: true
          },
          {
            title: t('Мої плани закупівель'),
            link: `${appConfig.get('OLD_DZO')}/cabinet/plans`,
            external: true
          },
          {
            title: t('Prozorro Market'),
            link: '/market'
          },
          {
            title: t('Бібліотека примірних специфікацій'),
            link: 'https://specifications.prozorro.org/?utm_source=Referred_from_eplatforms&utm_medium=Buyers_account_on_eplatform&utm_campaign=DZO',
            external: true
          },
          { divider: true },
          {
            title: t('Налаштування'),
            link: `${appConfig.get('OLD_DZO')}/cabinet/settings`,
            external: true
          },
          {
            title: t('Сповіщення'),
            link: `${appConfig.get('OLD_DZO')}/cabinet/notices`,
            external: true
          },
          { divider: true }
        ],

        seller: [
          {
            title: t('Наші пропозиції'),
            link: `${appConfig.get('OLD_DZO')}/cabinet/bids`,
            external: true
          },
          {
            title: t('Наша компанія'),
            link: `${appConfig.get('OLD_DZO')}/cabinet/company`,
            external: true
          },
          {
            title: t('Кваліфікації в Prozorro Market'),
            link: `${appConfig.get('OLD_DZO')}/cabinet/frameworks`,
            external: true
          },
          { divider: true },
          {
            title: t('Налаштування та інформування'),
            link: `${appConfig.get('OLD_DZO')}/cabinet/settings/main`,
            external: true
          },
          {
            title: t('Сповіщення'),
            link: `${appConfig.get('OLD_DZO')}/cabinet/notices`,
            external: true
          },
          { divider: true }
        ],

        cpe: [
          {
            title: t('Мої закупівлі'),
            link: `${appConfig.get('OLD_DZO')}/cabinet/tenders`,
            external: true
          },
          {
            title: t('Мої плани закупівель'),
            link: `${appConfig.get('OLD_DZO')}/cabinet/plans`,
            external: true
          },
          {
            title: t('Prozorro Market'),
            link: '/market'
          },
          {
            title: t('Кваліфікації в Prozorro Market'),
            link: `${appConfig.get('OLD_DZO')}/cabinet/frameworks`,
            external: true
          },
          {
            title: t('Моя Компанія'),
            link: `${appConfig.get('OLD_DZO')}/cabinet/company`,
            external: true
          },
          { divider: true },
          {
            title: t('Налаштування'),
            link: `${appConfig.get('OLD_DZO')}/cabinet/settings`,
            external: true
          },
          {
            title: t('Сповіщення'),
            link: `${appConfig.get('OLD_DZO')}/cabinet/notices`,
            external: true
          },
          { divider: true }
        ]
      }[user.type]
    : []

  const filteredMenu = (userMenu: MenuItem[], type?: ParticipantType): any => {
    let menu = userMenu
    if (type) {
      menu = userMenu.filter((section: any) => {
        if ('sub' in section) {
          return section.sub?.length > 0
        }
        return true
      })
    }

    return menu
  }

  const toggleMenu = (): void => {
    setIsOpen((prevIsOpen: boolean) => !prevIsOpen)
  }

  const login = authService.isLoggedIn
    ? [{
        title: t('Вихід'),
        onClick: () => { void authService.logout() }
      }]
    : (!desktop
        ? [
            {
              title: <><UserIcon /> {t('Увійти')}</>,
              link: '/login',
              state: { from: pathname }
            },
            {
              title: t('Реєстрація'),
              link: `${appConfig.get('OLD_DZO')}/registration/sellers`,
              external: true,
              style: { color: theme.color.dodgerblue }
            }
          ]
        : []
      )

  const menu = [...filteredMenu(userMenu, user?.type), ...mainMenu, ...login]

  return (<>
    <UiHeader menu={menu}>
      {mainMenu.filter((item: any) =>
        !item.divider && !item.langToggler).map((item: any) =>
          <NavItem key={item.title}
            title={item.title}
            link={item.link}
            external={item.external}
            menu={item.sub}
            open={isOpen}
            target={item.target}
            onClick={() => toggleMenu}
            onClickAway={() => {
              setIsOpen(false)
            }}
          />
      )}
      <LanguageToggler onChangeLang={changeLanguage} />
    </UiHeader>
  </>)
}

export default withTranslation('dzo')(Header)
