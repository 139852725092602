import { type ChipInterface } from './chip.interface'

export interface Chips {
  status?: ChipInterface[]
  classification?: ChipInterface[]
  road?: ChipInterface[]
  gmdn?: ChipInterface[]
  inn?: ChipInterface[]
  organizer?: ChipInterface[]
  supplier?: ChipInterface[]
  region?: ChipInterface[]
  fundManager?: ChipInterface[]
  funder?: ChipInterface[]
  ccce_ua?: ChipInterface[]

  valueAmount?: ChipInterface[]
  enquiryPeriod?: ChipInterface[]
  auctionPeriod?: ChipInterface[]
  tenderPeriod?: ChipInterface[]
  qualificationPeriod?: ChipInterface[]
  publicationDate?: ChipInterface[]
  plansPeriod?: ChipInterface[]
  plansPublished?: ChipInterface[]
  valueSum?: ChipInterface[]
  valueBudget?: ChipInterface[]

  typeSubjectProcurement?: ChipInterface[]
  procurementMethodType?: ChipInterface[]
  paymentType?: ChipInterface[]
  planYear?: ChipInterface[]
  keyword?: ChipInterface[]

  hasActiveMonitoring?: ChipInterface[]

  textRequirements?: ChipInterface[]
  numberRequirements?: ChipInterface[]
  booleanRequirements?: ChipInterface[]
}

export const CHIPS_RANGE_FROM_KEY = 'from'

export const CHIPS_RANGE_TO_KEY = 'to'

export const chipsKeys: Array<keyof Chips> = [
  'status',
  'classification',
  'road',
  'gmdn',
  'inn',
  'organizer',
  'supplier',
  'region',
  'fundManager',
  'valueAmount',
  'enquiryPeriod',
  'auctionPeriod',
  'tenderPeriod',
  'plansPeriod',
  'plansPublished',
  'valueSum',
  'valueBudget',
  'procurementMethodType',
  'typeSubjectProcurement',
  'paymentType',
  'planYear',
  'keyword',
  'qualificationPeriod',
  'publicationDate',
  'hasActiveMonitoring',
  'funder',
  'ccce_ua'
]

export const marketChipsKeys: Array<keyof Chips> = [
  'textRequirements',
  'numberRequirements',
  'booleanRequirements'
]
