import i18n from 'i18next'
import countries from 'i18n-iso-countries'
import ukLocale from 'i18n-iso-countries/langs/uk.json'
import enLocale from 'i18n-iso-countries/langs/en.json'

countries.registerLocale(ukLocale)
countries.registerLocale(enLocale)

export const transformValue = (value: string | number | boolean): string | number => {
  switch (value) {
    case true:
      return 'так'
    case false:
      return 'ні'
    case 'true':
      return 'так'
    case 'false':
      return 'ні'
    default: return value
  }
}

export const getCountryNames = (countryCodes: string[] | number[] | boolean[]): string => {
  return countryCodes.map(code => (typeof code === 'string') ? countries.getName(code, i18n.language) : code).join(', ')
}
