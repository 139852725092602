import { type RangeFilterInterface } from './range-filter-interface'
import { type procurementMethodTypes } from '../constants/procurement-method-types'
import { type BooleanRequirement, type NumberRequirement, type TextRequirement } from './market-requirements'

export interface SearchFiltersString {
  typeSubjectProcurement?: string
  paymentType?: string
  keyword?: string
  planYear?: string
  relatedProfileIds?: string
}

export const searchFilterStringKeys: string[] = [
  'typeSubjectProcurement',
  'paymentType',
  'keyword',
  'planYear',
  'relatedProfileIds'
]

export interface SearchFiltersRange {
  valueAmount?: RangeFilterInterface
  enquiryPeriod?: RangeFilterInterface
  auctionPeriod?: RangeFilterInterface
  tenderPeriod?: RangeFilterInterface
  valueSum?: RangeFilterInterface
  valueBudget?: RangeFilterInterface
  plansPeriod?: RangeFilterInterface
  plansPublished?: RangeFilterInterface
  qualificationPeriod?: RangeFilterInterface
  publicationDate?: RangeFilterInterface
}

export const searchFilterRangeKeys: string[] = [
  'valueAmount',
  'enquiryPeriod',
  'auctionPeriod',
  'tenderPeriod',
  'valueSum',
  'valueBudget',
  'plansPeriod',
  'plansPublished',
  'qualificationPeriod',
  'publicationDate'
]

export interface SearchFiltersArray {
  status?: string[]
  organizer?: string[]
  supplier?: string[]
  region?: string[]
  fundManager?: string[]
  procurementMethodType?: string[]
  funder?: string[]
  market_classification?: string[]
  relatedCategoryId?: string[]
}

export interface SearchFiltersClassificators {
  classification?: string[]
  road?: string[]
  gmdn?: string[]
  inn?: string[]
  ccce_ua?: string[]
}

export const searchFilterArrayKeys: string[] = [
  'status',
  'organizer',
  'region',
  'fundManager',
  'procurementMethodType',
  'supplier',
  'funder',
  'market_classification',
  'relatedCategoryId'
]

export const searchClassificatorFilters: string[] = [
  'classification',
  'road',
  'gmdn',
  'inn',
  'ccce_ua'
]

export interface SearchFiltersNumber {
  page: number
  perPage: number
}

export interface SearchFiltersBoolean {
  testMode?: boolean
  hasActiveMonitoring?: boolean
  isFavorite?: boolean
  isMyAnnounced?: boolean
  hasMyBid?: boolean
  isPublicated?: boolean
  isDraft?: boolean
  isWaitSign?: boolean
}

export const searchFiltersBooleanKeys: string[] = [
  'testMode',
  'hasActiveMonitoring',
  'isFavorite',
  'isMyAnnounced',
  'hasMyBid',
  'isPublicated',
  'isDraft',
  'isWaitSign'
]

export type SortKeys = 'date.desc' | 'date.asc'
| 'dateCreated.asc' | 'dateCreated.desc'
| 'value.desc' | 'value.asc'
| 'description.asc' | 'title.asc'
| 'default' | 'popularity'

export interface SearchSortOrder {
  sortOrder?: SortKeys
}

export interface SearchMarketFilters {
  textRequirements?: TextRequirement[]
  numberRequirements?: NumberRequirement[]
  booleanRequirements?: BooleanRequirement[]
}

export const searchMarketFiltersKeys: string[] = [
  'textRequirements',
  'numberRequirements',
  'booleanRequirements'
]

export interface SearchFilters extends
  SearchFiltersString,
  SearchFiltersArray,
  SearchFiltersClassificators,
  SearchFiltersRange,
  SearchFiltersNumber,
  SearchFiltersBoolean,
  SearchMarketFilters,
  SearchSortOrder { }

export type ProcurementMethodTypeKeys = keyof typeof procurementMethodTypes
