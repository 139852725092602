import { type Identifiers } from '../../util/search/types/identifiers'
import { type IdentifierCollection } from '../../util/search/types/identifier.collection'
import { fetchTransport } from '../../util/core/transport/fetch.transport'

class DataAccessIdentifiers {
  private readonly urls: Record<Identifiers, string> = {
    tender: '/api/tender_identifiers',
    contract: '/api/contract_identifiers',
    plan: '/api/plan_identifiers',
    contract_supplier: '/api/contract_suppliers'
  }

  public async load (
    type: Identifiers,
    keyword?: string,
    ids?: string[]
  ): Promise<IdentifierCollection> {
    const params = new URLSearchParams()
    if (keyword && keyword !== '') {
      params.append('keyword', keyword)
    } else if ((ids != null) && ids.length > 0) {
      ids.forEach(
        id => { params.append('id[]', id) }
      )
    }

    const response = await fetchTransport.get({
      url: this.urls[type],
      searchParams: params
    })

    if (response.status >= 400 && response.status < 600) {
      throw new Error(
        response.status.toString()
      )
    }

    const json = await response.json()
    const result: IdentifierCollection = {}

    if (json['@type'] === 'hydra:Collection') {
      if (json['hydra:member'].length > 0) {
        const items = json['hydra:member']
        items.forEach(
          (item: any) => result[item.id] = (item.legalName) ? item.legalName : item.id
        )
      }
    }
    return result
  }
}

export const dataAccessIdentifiers = new DataAccessIdentifiers()
