import React from 'react'
import * as Styled from './footer.styled'
import { Link } from 'react-router-dom'
import { useTranslation, withTranslation } from 'react-i18next'
import UiFollowUs from '../../follow-us/ui-follow-us'
import { scrollToSearch } from 'ui/search/helper/scrollToSearch'
import { authService } from 'util/core/service/auth.service'
import { appConfig } from 'util/core/config/app-config.instance'

interface UiFooterProps {
  logoPaths: Record<string, string>
  menu: Array<{
    name: string
    link: string
    outerLink?: boolean
  }>
}

const UiFooter: React.FC<UiFooterProps> = ({ logoPaths, menu }) => {
  const { t, i18n } = useTranslation()
  const img = <img src={logoPaths[i18n.language]} style={{ height: '40px' }} alt={t('Державні закупівлі онлайн')}/>
  return <Styled.Wrap>
    <Styled.Row>
      {authService.isLoggedIn
        ? <a href={`${appConfig.get('OLD_DZO')}`}>{img}</a>
        : <Link to={'/?scroll-search'} onClick={scrollToSearch}>{img}</Link>
      }
      <Styled.Menu>
          {menu.map(item => <Styled.MenuItem key={item.name}>
            {item.outerLink
              ? <a href={item.link} target='_blank' rel="noopener noreferrer nofollow">{item.name}</a>
              : <Link to={item.link}>{item.name}</Link>}
          </Styled.MenuItem>)}
      </Styled.Menu>
    </Styled.Row>
    <Styled.Row>
      <Styled.Copyright>© dzo.com.ua, 2014 - {(new Date()).getFullYear()}</Styled.Copyright>
      <UiFollowUs />
    </Styled.Row>
  </Styled.Wrap>
}

export default withTranslation('dzo')(UiFooter)
