import React, { createContext, useContext, useEffect, useState, type ReactNode } from 'react'
import { searchClassificatorFilters, type SearchFiltersClassificators, type SearchFilters, type SearchFiltersArray } from '../types/search-filters'
import { type Chips } from '../types/chips'
import { type SearchClassificatorsState, type SearchState } from '../types/search-state'
import { type SearchRecordsType } from '../types/search-records.type'
import { useLocation } from 'react-router-dom'
import { UrlParser } from '../helpers/url.parser'
import { useTranslation } from 'react-i18next'
import { UrlBuilder } from '../helpers/url.builder'
import { appConfig } from 'util/core/config/app-config.instance'
import { dataAccessIdentifiers } from 'data-access/search/data-access-identifiers'
import { dataAccessClassification } from 'data-access/classification/data-access-classification'
import { type ChipInterface } from '../types/chip.interface'
import { scheme } from '../constants/payment-types'
import { type Schemes } from 'data-access/classification/type/schemes'
import { type Identifiers } from '../types/identifiers'
import { useUserProfile } from 'util/core/service/user-provider'

export const defaultFiltersMap: Record<SearchRecordsType, SearchFilters> = {
  tenders: {
    page: 1,
    perPage: 10,
    sortOrder: 'dateCreated.desc'
  },
  plans: {
    page: 1,
    perPage: 10,
    sortOrder: 'dateCreated.desc'
  },
  contracts: {
    page: 1,
    perPage: 10,
    sortOrder: 'dateCreated.desc'
  },
  classifications: {
    page: 1,
    perPage: 24,
    sortOrder: 'default'
  },
  categories: {
    page: 1,
    perPage: 24,
    sortOrder: 'default'
  },
  products: {
    page: 1,
    perPage: 24,
    sortOrder: 'default'
  }
}

export const defaultClassificatorFilters: SearchFiltersClassificators = {
  classification: [],
  road: [],
  gmdn: [],
  inn: [],
  ccce_ua: []
}

interface SearchContextProps {
  filters: SearchFilters
  chips: Chips
  defaultFilters: SearchFilters
  defaultChips: Chips
  updateSearch: (state: SearchState, full?: boolean) => Promise<void>
  clearSearch: () => void
  parseUrl: () => Promise<void>
  updateUrl: (filters: SearchFilters) => void
  classificatorFilters: SearchFiltersClassificators
  classificatorChips: Chips
  updateClassificator: (state: SearchClassificatorsState) => Promise<void>
}
const SearchContext = createContext<SearchContextProps>(
  {
    filters: { ...defaultFiltersMap.tenders },
    chips: {},
    defaultFilters: { ...defaultFiltersMap.tenders },
    defaultChips: {},
    updateSearch: async () => {},
    clearSearch: () => {},
    parseUrl: async () => {},
    updateUrl: () => {},
    classificatorFilters: defaultClassificatorFilters,
    classificatorChips: {},
    updateClassificator: async () => {}
  }
)

export const SearchProvider: React.FC<{ type: SearchRecordsType, children?: ReactNode }> = ({ type, children }) => {
  const { user } = useUserProfile()

  const defaultFilters = defaultFiltersMap[type]
  const defaultChips: Chips = {}

  const [filters, setFilters] = useState<SearchFilters>(user ? { testMode: user.isTestMode, ...defaultFilters } : defaultFilters)
  const [chips, setChips] = useState<Chips>({ ...defaultChips })
  const [classificatorFilters, setClassificatorFilters] = useState<SearchFiltersClassificators>(defaultClassificatorFilters)
  const [classificatorChips, setClassificatorChips] = useState<Chips>({})

  const { pathname, search } = useLocation()
  const { t, i18n } = useTranslation()

  useEffect(() => {
    setFilters({ ...filters, testMode: user?.isTestMode })
  }, [user])

  const updateSearch = async (state: SearchState, full?: boolean): Promise<void> => {
    const stateFilters = user ? { testMode: user.isTestMode, ...state.filters } : state.filters

    if (full) {
      setFilters(stateFilters ? { ...defaultFilters, ...stateFilters } : { ...defaultFilters })
      setChips(state.chips ? { ...state.chips } : {})
      return
    }

    if (state.filters) {
      setFilters((prevFilters) => ({ ...prevFilters, ...stateFilters }))
    }

    if (state.chips) {
      setChips((prevChips) => ({ ...prevChips, ...state.chips }))
    }
  }

  const updateClassificator = async (state: SearchClassificatorsState): Promise<void> => {
    if (state.filters) {
      setClassificatorFilters((prevFilters) => ({ ...prevFilters, ...state.filters }))
    }

    if (state.chips) {
      setClassificatorChips((prevChips) => ({ ...prevChips, ...state.chips }))
    }
  }
  const clearSearch = (): void => {
    setFilters({ ...defaultFilters })
    setChips({ ...defaultChips })
  }

  const parseUrl = async (): Promise<any> => {
    const searchArray = (search || '').split('?')
    const parser = new UrlParser(
      searchArray[searchArray.length - 1],
      t,
      type
    )
    const chips = parser.chips

    for (const key of searchClassificatorFilters) {
      let pageChips = chips[key as keyof SearchFiltersClassificators]
      if (pageChips !== undefined) {
        const ids: string[] = []
        pageChips.forEach(item => ids.push(item.key))
        if (ids.length > 0) {
          pageChips = []
          const selectedRecords = await dataAccessClassification.load(scheme(key as keyof SearchFiltersClassificators) as Schemes, '', '', ids)
          selectedRecords.forEach(record => {
            (pageChips as ChipInterface[]).push({
              key: record.id,
              title: `${record.id} ${record.name}`
            })
          })
        }
        chips[key as keyof SearchFiltersClassificators] = pageChips
      }
    }

    const identifierChipKeys = ['organizer', 'supplier']
    for (const key of identifierChipKeys) {
      let pageChips = chips[key as keyof Omit<SearchFiltersArray, 'market_classification' | 'relatedCategoryId'>]
      if (pageChips !== undefined) {
        const ids: string[] = []
        pageChips.forEach(item => ids.push(item.key))
        if (ids.length > 0) {
          pageChips = []
          let identifiersType: Identifiers = 'contract_supplier'
          if (key === 'organizer') {
            if (type === 'tenders') {
              identifiersType = 'tender'
            } else if (type === 'plans') {
              identifiersType = 'plan'
            } else {
              identifiersType = 'contract'
            }
          }
          const selectedRecords = await dataAccessIdentifiers.load(identifiersType, '', ids)
          for (const id in selectedRecords) {
            (pageChips).push({
              key: id,
              title: `${id} ${selectedRecords[id]}`
            })
          }
        }
        chips[key as keyof Omit<SearchFiltersArray, 'market_classification' | 'relatedCategoryId'>] = pageChips
      }
    }
    await updateSearch({
      filters: { ...parser.filters },
      chips: { ...chips }
    }, true)
  }

  const updateUrl = (filters: SearchFilters): void => {
    // here we need to do check url current to not going to make 2 url update when page opened
    // when page opened url parsed -> filter changed -> new url generated
    // so if new url is equal to old url we will not do history update
    const searchArray = (search || '').split('?')
    const searchParams = new URLSearchParams(searchArray[searchArray.length - 1])
    const urlBuilder = new UrlBuilder(filters)

    searchParams.sort()
    const currentSearch = searchParams.toString()

    if (currentSearch !== urlBuilder.searchString) {
      const basename = (i18n.language === appConfig.get('defaultLanguage')) ? '' : `/${i18n.language}`
      const newUrl = `${basename}${pathname}?${urlBuilder.searchString}`
      window.history.replaceState({}, document.title, newUrl)
    }
  }

  return (
    <SearchContext.Provider value={{
      filters,
      chips,
      defaultFilters,
      defaultChips,
      updateSearch,
      clearSearch,
      classificatorFilters,
      classificatorChips,
      updateClassificator,
      parseUrl,
      updateUrl
    }}>
      {children}
    </SearchContext.Provider>
  )
}

export const useSearch = (): SearchContextProps => {
  const context = useContext(SearchContext)
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider')
  }
  return context
}
