import { type ProductStatusType } from 'data-access/market/type/market-product-type'
import { t } from '../helpers/t-converter'
import { type SearchRecordsType } from '../types/search-records.type'

export const TenderStatuses = [
  'active.enquiries',
  'active.tendering',
  'active.pre-qualification',
  'active.complaint',
  'active.pre-qualification.stand-still',
  'active.auction',
  'active.qualification',
  'active.qualification.stand-still',
  'active.awarded',
  // 'active.rectification',
  'complete',
  'cancelled',
  'unsuccessful',
  'draft.unsuccessful',
  'draft.pending',
  'active',
  'active.stage2.pending',
  'active.stage2.waiting',
  'active.stage2'
]
export const TenderStatusSearch = [
  'active.enquiries',
  'active.tendering',
  'active.pre-qualification',
  'active.complaint',
  'active.auction',
  'active.qualification',
  'active.awarded',
  // 'active.rectification',
  'complete',
  'unsuccessful',
  'cancelled'
]
export const tenderStatusName = (status: string): string => {
  switch (status) {
    case 'active.enquiries': return t('tender.status.active.enquiries')
    case 'active.tendering': return t('tender.status.active.tendering')
    case 'active.pre-qualification': return t('tender.status.active.pre-qualification')
    case 'active.complaint': return t('tender.status.active.complaint')
    case 'active.pre-qualification.stand-still': return t('tender.status.active.pre-qualification.stand-still')
    case 'active.auction': return t('tender.status.active.auction')
    case 'active.qualification': return t('tender.status.active.qualification')
    case 'active.qualification.stand-still': return t('tender.status.active.qualification.stand-still')
    case 'active.awarded': return t('tender.status.active.awarded')
    // case 'active.rectification': return t('tender.status.active.rectification')
    case 'complete': return t('tender.status.complete')
    case 'cancelled': return t('tender.status.cancelled')
    case 'unsuccessful': return t('tender.status.unsuccessful')
    case 'draft.unsuccessful': return t('tender.status.draft.unsuccessful')
    case 'draft.pending': return t('tender.status.draft.pending')
    case 'active': return t('tender.status.active')
    case 'active.stage2.pending': return t('tender.status.active.stage2.pending')
    case 'active.stage2.waiting': return t('tender.status.active.stage2.waiting')
    case 'active.stage2': return t('tender.status.active.stage2')
    default: return ''
  }
}

export const PlanStatuses = [
  'draft',
  'scheduled',
  'complete',
  'cancelled'
]
export const PlanStatusSearch = [
  'scheduled',
  'complete',
  'cancelled'
]
export const planStatusName = (status: string): string => {
  switch (status) {
    case 'draft': return t('plan.status.draft')
    case 'scheduled': return t('plan.status.scheduled')
    case 'complete': return t('plan.status.complete')
    case 'cancelled': return t('plan.status.cancelled')
    default: return ''
  }
}

export const ContractStatuses = [
  'active',
  'terminated',
  'cancelled',
  'pending'
]
export const contractStatusName = (status: string): string => {
  switch (status) {
    case 'active': return t('contract.status.active')
    case 'terminated': return t('contract.status.terminated')
    case 'cancelled': return t('contract.status.cancelled')
    case 'pending': return t('contract.status.pending')
    default: return ''
  }
}

export type TenderStatusesType = typeof TenderStatuses[number]
export type ContractStatusesType = typeof ContractStatuses[number]
export type PlanStatusesType = typeof PlanStatuses[number]
export type Statuses = TenderStatusesType | ContractStatusesType | PlanStatusesType

export const typesList = (type: SearchRecordsType): ((status: string) => string) => {
  switch (type) {
    case 'contracts': return contractStatusName
    case 'tenders': return tenderStatusName
    case 'plans': return planStatusName
    default: return () => ''
  }
}

export const LocalizationProductStatuses = [
  'active',
  'hidden',
  'inactive',
  'draft'
]
export const localizationProductStatusName = (status: ProductStatusType): string => {
  switch (status) {
    case 'active': return t('localization-product.status.active')
    case 'hidden': return t('localization-product.status.hidden')
    case 'inactive': return t('localization-product.status.inactive')
    case 'draft': return t('localization-product.status.draft')
    default: return ''
  }
}
